<template>
	<w-layout column>
		<w-flex v-if="canSelect">
			<v-select
				v-model="application.accounting_firms"
				:items="filteredAccountingFirms"
				:label="accountingFirmPlaceHolder"
				multiple
				item-text="name"
				return-object
			>
				<template v-slot:prepend-item>
					<w-search-input v-model="search" hide-details single-line :placeholder="accountingFirmPlaceHolder" />
				</template>
				<template v-slot:selection="{ item, index }">
					<span v-if="index > 0" class="mr-1">,</span>
					<span v-text="item.name + ' (' + item.id + ')'"></span>
				</template>
			</v-select>
		</w-flex>
		<w-flex v-if="canSelect">
			<v-textarea v-model="domains" readonly no-resize rows="3" color="primary" :label="domainFieldName"></v-textarea>
		</w-flex>
		<w-flex v-else v-text="domains"></w-flex>
	</w-layout>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'MobileAppDomains',
	props: {
		canSelect: {
			required: false,
			type: Boolean,
			default: true
		},
		value: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			search: ''
		}
	},
	computed: {
		...mapState({
			accountingFirms: state => state.mobileapps.accountingFirms
		}),
		application: {
			get: function () {
				return this.value
			},
			set: function (val) {
				this.$emit('input', val)
			}
		},
		filteredAccountingFirms: function () {
			const search = this.search ? this.search.trim().toLowerCase() : ''
			const searchedTextRegEx = new RegExp(search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'gi')
			const domainsAlreadyUsed = this.domainsAlreadyUsed()
			return this.accountingFirms
				.filter(accountingFirm => [null, this.application.id].includes(accountingFirm.mobile_app_id))
				.filter(accountingFirm => this.application.id ? true : !(domainsAlreadyUsed.some(dom => accountingFirm.domains.map(domain => domain.url).includes(dom))))
				.filter(accountingFirm => accountingFirm.name.toLowerCase().includes(search))
				.map(accountingFirm => {
					if (!search || !search.length) {
						accountingFirm.formatted_name = accountingFirm.name
					} else {
						accountingFirm.formatted_name = accountingFirm.name.replace(searchedTextRegEx, '<b>$&</b>')
					}
					return accountingFirm
				})
		},
		domains: {
			get: function () {
				return this.application?.accounting_firms
					?.map(accountingFirm => accountingFirm.domains.map(domain => domain.url))
					.flat()
					.filter((domain, index, result) => index === result.indexOf(domain))
					.sort()
					.join('\n')
			},
			set: function () {
				throw new Error('Set is forbidden for "domains"')
			}
		},
		accountingFirmPlaceHolder: function () {
			return this.$t('mobileapp.actions.select-accounting-firm')
		},
		domainFieldName: function () {
			return this.$t('mobileapp.fields.domains')
		}
	},
	methods: {
		domainsAlreadyUsed: function () {
			return this.accountingFirms.filter(af => af.mobile_app_id != null && af.mobile_app_id != this.application.id)
				.map(accountingFirm => accountingFirm.domains.map(domain => domain.url))
				.flat()
		}
	}
}
</script>
